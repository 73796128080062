import "normalize.css";
import "../scss/style.scss";

import $ from "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import DeviceDetector from "device-detector-js";
const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);

if (device.device.type == "smartphone") {
  var offset = 100;
  var scrollLimit = 300;
} else {
  var offset = 200;
  var scrollLimit = 500;
}

import SmoothScroll from "smooth-scroll";
var scroll = new SmoothScroll('a[href*="#"]', {
  speed: offset,
  offset: function() {
    return offset;
  }
});

var interval = null;
$(window).on("scroll", function() {
  $("header#header").fadeIn(1000);

  if ($(this).scrollTop() < scrollLimit ) {
    $("body").addClass("top");
    $("header#header").fadeIn(1000);
    clearInterval(interval);
  } else {
    $("body").removeClass("top");
  }
});

function clearError() {
  $(".error[data-name='order_name']").text("");
  $(".error[data-name='kana_name']").text("");
  $(".error[data-name='tel']").text("");
  $(".error[data-name='mail']").text("");
  $(".error[data-name='equal']").text("");
  $(".error[data-name='zip']").text("");
  $(".error[data-name='prefecture']").text("");
  $(".error[data-name='access1']").text("");
  $(".error[data-name='access2']").text("");
  $(".error[data-name='day']").text("");
  $(".error[data-name='text']").text("");
}

$(function(){
  $('#hero .slide').slick({
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    fade: true,
  });

  $("form button.back").on("click", function() {
    clearError();
    $("form [name='checked']").val(0);
    $("form .checkform").hide();
    $("form .inputform").show();
    $("form button.back").hide();
    $("form button.send").hide();
    $("form button.check").show();
  });

  $("form").on("submit", function() {
    clearError();
    const order_name = $(this).find("[name='order_name']").val();
    const kana_name = $(this).find("[name='kana_name']").val();
    const tel = $(this).find("[name='tel']").val();
    const mail = $(this).find("[name='mail']").val();
    const zip = $(this).find("[name='zip']").val();
    const prefecture = $(this).find("[name='prefecture']").val();
    const access1 = $(this).find("[name='access1']").val();
    const access2 = $(this).find("[name='access2']").val();
    const day = $(this).find("[name='day']").val();
    const text = $(this).find("[name='text']").val();
    $(".checkform [data-name='order_name']").text(order_name);
    $(".checkform [data-name='kana_name']").text(kana_name);
    $(".checkform [data-name='tel']").text(tel);
    $(".checkform [data-name='mail']").text(mail);
    $(".checkform [data-name='zip']").text(zip);
    $(".checkform [data-name='prefecture']").text(prefecture);
    $(".checkform [data-name='access1']").text(access1);
    $(".checkform [data-name='access2']").text(access2);
    $(".checkform [data-name='day']").text(day);
    $(".checkform [data-name='text']").text(text);
    
    let data = $(this).serializeArray();
    const checked = $(this).find("[name='checked']").val();
    var isValidate = false;
    if (checked == "0") {
      data.push({
        name: "validate",
        value: "1"
      })
      isValidate = true;
    } else {
      data.push({
        name: "validate",
        value: "0"
      })
    }

    $.ajax({
      type: "POST",
      url: "php/contact.php",
      data: data,
      success: function(response) {
        if (response.status) {
          if (isValidate) {
            $("form [name='checked']").val(1);
            $("form .checkform").show();
            $("form .inputform").hide();
            $("form button.back").show();
            $("form button.send").show();
            $("form button.check").hide();
          } else {
            $("form .checkform").hide();
            $("form button.back").hide();
            $("form button.send").hide();
            $("form p.thanks").show();
          }
          
        } else {
          const data = response.data;
          console.log(data);
          for (let key in data) {
            const e = $("form").find(".error[data-name='" + key + "']");
            e.text(data[key][0]);
          }
        }
        scroll.animateScroll($("#section5").get(0));
      },
      error: function(response) {
        console.log(response);
      }
    })
    return false;
  })
});
